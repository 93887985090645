import styled from "styled-components";
import { fonts, palette } from "../styles";
import Button from "./Button";
import Icon from "./Icon";
import logo from "../assets/logo_white_square_noText_transparentBackground_512px.png";
import Link from "./Link";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const titleBreakPoint = "680px";
const navItemsBreakpoint = "1080px";

const Container = styled.nav`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: ${palette.primary};
  color: ${palette.primaryTextComplement};
  font-weight: 700;
  padding: 8px 40px;
  display: flex;
  align-items: center;
  gap: 24px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
`;

const ThisTakesTheSameSpaceAtTopOfPage = styled.div`
  height: 64px;
`;

const Left = styled.div`
  flex: 1 0 0;
  display: flex;
`;

const Center = styled.div`
  flex: 0 0 auto;
`;

const Right = styled.div`
  flex: 1 0 0;
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.span`
  @media (max-width: ${titleBreakPoint}) {
    display: none;
  }
  margin-left: 8px;
  font-size: 32px;
  font-family: ${fonts.brand};
  font-weight: normal;
`;

const TitleLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

const navItems = [
  {
    label: "Services",
    path: "/services",
  },
  {
    label: "Incentives",
    path: "/incentives",
  },
  {
    label: "Contact",
    path: "/contact",
  },
  {
    label: "Get started",
    path: "/get-estimate",
  },
];

const navItemsWithHome = [
  {
    label: "Home",
    path: "/",
  },
  ...navItems,
];

const NavItemContainer = styled.div`
  padding: 8px;
  margin-right: 8px;
  ${(p) =>
    p.selected
      ? `border-bottom: 2px solid ${palette.primaryTextComplement}`
      : ""}
`;

const NavItem = ({ label, path, isExternal }) => {
  const { pathname } = useLocation();

  if (path) {
    return (
      <Link to={path} isExternal={isExternal} inheritStyles>
        <NavItemContainer selected={!isExternal && pathname === path}>
          {label}
        </NavItemContainer>
      </Link>
    );
  }

  return <NavItemContainer>{label}</NavItemContainer>;
};

const NavItemsRow = styled.div`
  display: flex;
  @media (max-width: ${navItemsBreakpoint}) {
    display: none;
  }
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
`;

const MenuIconWrapper = styled.div`
  display: none;
  @media (max-width: ${navItemsBreakpoint}) {
    display: flex;
  }
`;

const NavItemsOverlay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;
  width: ${(p) => (p.showMenu ? "100%" : "0")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: ${palette.primary};
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.5s;
`;

const NavItemsMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 8px;
  align-items: flex-end;
`;

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <ThisTakesTheSameSpaceAtTopOfPage />
      <Container>
        <Left>
          <Link to="/" inheritStyles>
            <TitleLinkContainer>
              <img src={logo} height={48} alt="Logo" />
              <Title>
                RED PEAK <b>SOLAR</b>
              </Title>
            </TitleLinkContainer>
          </Link>
        </Left>
        <Center></Center>
        <Right>
          <NavItemsRow>
            {navItems.map((item) => (
              <NavItem key={item.path || item.label} {...item} />
            ))}
          </NavItemsRow>
          <MenuIconWrapper>
            <Button variant="icon" onClick={() => setShowMenu(true)}>
              <Icon name="menu" />
            </Button>
          </MenuIconWrapper>
        </Right>
        <NavItemsOverlay showMenu={showMenu}>
          <Button
            margin="16px 24px 0 8px"
            variant="icon"
            onClick={() => setShowMenu(false)}
          >
            <Icon name="menu" />
          </Button>
          <NavItemsMenu>
            {navItemsWithHome.map((item) => (
              <NavItem key={item.path || item.label} {...item} />
            ))}
          </NavItemsMenu>
        </NavItemsOverlay>
      </Container>
    </>
  );
};

export default NavBar;
