import styled from "styled-components";
import aerialImg from "../../assets/aerial.jpg";

const Background = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  z-index: -1;
  height: calc(100vh - 64px);
  background-image: url(${aerialImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const Container = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Hero = ({ children }) => {
  return (
    <>
      <Background />
      <Container>{children}</Container>
    </>
  );
};

export default Hero;
