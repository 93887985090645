import styled, { css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const allLinks = css`
  ${(p) => (p.width ? `width: ${p.width};` : "")}
  margin: ${(p) => p.margin};
  ${(p) =>
    p.$inheritStyles
      ? `
      color: inherit;
      text-decoration: inherit;
    `
      : ""}
`;

const StyledExternalLink = styled.a`
  ${allLinks}
`;

const StyledRouterLink = styled(RouterLink)`
  ${allLinks}
`;

const Link = ({
  to,
  children,
  width,
  target,
  margin = "0",
  disabled = false,
  isExternal = false,
  inheritStyles = false,
  id,
}) => {
  if (isExternal) {
    return (
      <StyledExternalLink
        href={to}
        width={width}
        margin={margin}
        disabled={disabled}
        $inheritStyles={inheritStyles}
        target={target}
        id={id}
      >
        {children}
      </StyledExternalLink>
    );
  }

  return (
    <StyledRouterLink
      to={to}
      width={width}
      margin={margin}
      disabled={disabled}
      $inheritStyles={inheritStyles}
      target={target}
      id={id}
    >
      {children}
    </StyledRouterLink>
  );
};

export default Link;
