import styled from "styled-components";
import { palette } from "../../styles";
import { Footer, NavBar } from "../../ui";

const Container = styled.div``;

const Content = styled.div`
  background-color: ${palette.background};
  margin: 0 16px;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  margin: 16px;
  font-size: 32px;
  font-weight: 700;
  color: ${palette.secondary};

  @media (min-width: 960px) {
    margin-top: 80px;
    font-size: 56px;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  margin-bottom: 40px;
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${palette.secondary};
`;

const SectionList = styled.div`
  max-width: 960px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Privacy = () => {
  return (
    <Container>
      <NavBar />
      <Top>
        <Title>Red Peak Solar privacy policy</Title>
      </Top>
      <Content>
        <SectionList>
          <div>
            <p>
              {
                "Red Peak Solar takes customer privacy very seriously. This policy describes the ways in which customer information is collected and used on our site. By using our site, you agree to the collection and use of your information as described by this policy."
              }
            </p>
          </div>

          <div>
            <SectionTitle>Information collected</SectionTitle>
            <p>
              {
                "We collect your home address, electricity provider, first and last name, email, and phone number when you choose to share it via the Red Peak Solar site. We use the information collected in one or more of the following ways:"
              }
            </p>
            <ul>
              <li>{"To answer any customer inquiries."}</li>
              <li>{"To create a detailed design specific to the customer."}</li>
              <li>
                {
                  "To create a detailed solar proposal with approximate installation figures."
                }
              </li>
              <li>
                {
                  "To set up a meeting with the customer to discuss designs and proposals."
                }
              </li>
              <li>
                {"To streamline and enhance the user experience on our site."}
              </li>
            </ul>
          </div>

          <div>
            <SectionTitle>Third party links</SectionTitle>
            <p>
              {
                "This site may contain links to other websites that Red Peak Solar does not own or operate. This policy does not apply to any such third party links. If you choose to click on said links, please be aware that you will be subject to their separate privacy policies."
              }
            </p>
          </div>

          <div>
            <p>{"This policy was last updated September 2022."}</p>
          </div>
        </SectionList>
      </Content>
      <Footer />
    </Container>
  );
};

export default Privacy;
