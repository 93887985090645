import styled from "styled-components";

const StyledTextarea = styled.textarea`
  margin: ${(p) => p.margin};
  width: ${(p) => p.width};
`;

const Textarea = ({
  value,
  onChange,
  margin = "0",
  placeholder,
  width = "100%",
}) => {
  return (
    <StyledTextarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      margin={margin}
      placeholder={placeholder}
      width={width}
      rows={5}
    />
  );
};

export default Textarea;
