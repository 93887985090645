import styled, { css } from "styled-components";
import { palette } from "../styles";

// const rippleKeyframes = keyframes`
//   to {
//     transform: scale(4);
//     opacity: 0;
//   }
// `;

// const rippleCss = css`
//   position: relative;
//   overflow: hidden;
//   transition: background 200ms;
//   & > span.ripple {
//     position: absolute;
//     border-radius: 50%;
//     transform: scale(0);
//     animation: ${rippleKeyframes} 600ms linear;
//     background-color: rgba(128, 128, 128, 0.5);
//   }
// `;

const allButtons = css`
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: ${(p) => p.margin};
  ${(p) => (p.width ? `width: ${p.width};` : "")}
  &:disabled {
    cursor: not-allowed;
  }
`;

const LinkButton = styled.button`
  ${allButtons}
  background-color: transparent;
  color: ${palette.link};
  &:disabled {
    color: ${palette.linkDisabled};
  }
`;

const PlainButton = styled.button`
  ${allButtons}
  background-color: transparent;
  &:disabled {
    color: ${palette.linkDisabled};
  }
`;

const IconButton = styled.button`
  ${allButtons}
  background-color: transparent;
  padding: 4px;
  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
  }
`;

const primary = css`
  border-color: ${palette.primary};
  background-color: ${palette.primary};
  color: ${palette.primaryTextComplement};
  &:hover {
    border-color: ${palette.primaryHighlight};
    background-color: ${palette.primaryHighlight};
  }
  &:disabled {
    border-color: ${palette.primaryDisabled};
    background-color: ${palette.primaryDisabled};
    color: ${palette.primaryTextComplement};
  }
`;

const secondary = css`
  border-color: ${palette.primary};
  background-color: ${palette.primaryTextComplement};
  color: ${palette.primary};
  &:hover {
    border-color: ${palette.primaryHighlight};
    color: ${palette.primaryHighlight};
  }
  &:disabled {
    border-color: ${palette.primaryDisabled};
    color: ${palette.primaryDisabled};
  }
`;

const danger = css`
  border-color: ${palette.error};
  background-color: ${palette.error};
  color: ${palette.errorTextComplement};
  &:hover {
    border-color: ${palette.errorHighlight};
    background-color: ${palette.errorHighlight};
  }
  &:disabled {
    border-color: ${palette.errorDisabled};
    background-color: ${palette.errorDisabled};
    color: ${palette.errorTextComplement};
  }
`;

const BaseButton = styled.button`
  ${allButtons}
  padding: 0 16px;
  height: 48px;
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  font-weight: 700;
  min-width: 120px;
  ${(p) => (p.width ? `width: ${p.width};` : "")}
  ${(p) =>
    p.variant === "secondary"
      ? secondary
      : p.variant === "danger"
      ? danger
      : primary}
`;

// const createRipple = (event) => {
//   const button = event.currentTarget;

//   const circle = document.createElement("span");
//   const diameter = Math.max(button.clientWidth, button.clientHeight);
//   const radius = diameter / 2;

//   circle.style.width = circle.style.height = `${diameter}px`;
//   circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
//   circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
//   circle.classList.add("ripple");

//   const ripple = button.getElementsByClassName("ripple")[0];

//   if (ripple) {
//     ripple.remove();
//   }

//   button.appendChild(circle);
// };

const Button = ({
  onClick = () => {},
  children,
  variant = "primary",
  width,
  margin = "0",
  disabled = false,
  id,
}) => {
  // const animatedOnClick = (event) => {
  //   if (!disabled) {
  //     createRipple(event);
  //   }
  //   onClick();
  // };

  if (variant === "link") {
    return (
      <LinkButton
        width={width}
        onClick={onClick}
        id={id}
        disabled={disabled}
        margin={margin}
      >
        {children}
      </LinkButton>
    );
  }

  if (variant === "plain") {
    return (
      <PlainButton
        width={width}
        onClick={onClick}
        id={id}
        disabled={disabled}
        margin={margin}
      >
        {children}
      </PlainButton>
    );
  }

  if (variant === "icon") {
    return (
      <IconButton
        width={width}
        onClick={onClick}
        id={id}
        disabled={disabled}
        margin={margin}
      >
        {children}
      </IconButton>
    );
  }

  return (
    <BaseButton
      variant={variant}
      width={width}
      onClick={onClick}
      id={id}
      disabled={disabled}
      margin={margin}
    >
      {children}
    </BaseButton>
  );
};

export default Button;
