import styled from "styled-components";
import MaterialIconRounded from "./MaterialIconRounded";
import NothingIcon from "./NothingIcon";

/**
 * You can look up what material icons can be included here:
 * https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Icons
 * Our preferred icons are the Material Icons set (as opposed to Material
 * Symbols) and the Rounded category. For these, just look up the name of
 * the icon that should be used in the span (see details on right after
 * clicking the icon). Then in `names`, include the name of the icon as
 * the key and MaterialIconRounded as the value.
 *
 * If we ever need to include icons from a different source (like a
 * category other than Rounded, or Material Symbols, or a non-Material
 * source), we can make a new component similar to MaterialIconRounded
 * and set the value in `names` for that icon to the different component.
 */

const names = {
  menu: MaterialIconRounded,
  mouse: MaterialIconRounded,
  nothing: NothingIcon,
  open_in_new: MaterialIconRounded,
  star: MaterialIconRounded,
  star_outline: MaterialIconRounded,
  warning: MaterialIconRounded,
};

const Container = styled.div`
  // Without this, you get weird extra space underneath. Also,
  // inline-flex is better than flex because we want the default
  // behavior of icons to act like spans, not divs.
  display: inline-flex;
  font-size: ${(p) => p.fontSize};
  color: ${(p) => p.color};
  margin: ${(p) => p.margin};
  width: ${(p) => p.fontSize};
  height: ${(p) => p.fontSize};
  // In case the parent element uses display:flex, this ensures
  // that the icon or this Container won't shrink or grow.
  flex: 0 0 auto;
`;

// fontSize should normally be 18px, 24px, 36px, or 48px. Occasionally,
// it could be set to 'inherit' so it flows better with text.
const Icon = ({ name, fontSize = "24px", color = "inherit", margin = "0" }) => {
  const _name = name in names ? name : "warning";
  const Component = names[_name];

  return (
    <Container fontSize={fontSize} color={color} margin={margin}>
      <Component
        name={_name}
        fontSize={fontSize}
        color={color}
        margin={margin}
      />
    </Container>
  );
};

export default Icon;
