import styled from "styled-components";
import { colors } from "../styles";
import chrisHouseImg from "../assets/chris_house_cropped_smaller.png";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const PicWithTitle = styled.div`
  flex: 2 0 0;
  padding: min();
  background-image: url(${chrisHouseImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: ${colors.white};
  box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px;
  min-width: 720px;
  max-height: 400px;
  height: 30vw;
`;

const Blurb = styled.div`
  background-color: ${colors.blueDark};
  flex: 1 0 0;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 64px;
  min-width: 360px;
`;

const Title = styled.div`
  font-size: 56px;
  font-weight: 700;
`;

const HeroWithSideText = ({ blurb, title }) => {
  return (
    <Container>
      <PicWithTitle>
        <Title>{title}</Title>
      </PicWithTitle>
      <Blurb>{blurb}</Blurb>
    </Container>
  );
};

export default HeroWithSideText;
