import styled from "styled-components";

const Span = styled.span`
  user-select: none;
  font-size: inherit;
`;

const MaterialIconRounded = ({ name }) => {
  return <Span className="material-icons-round">{name}</Span>;
};

export default MaterialIconRounded;
