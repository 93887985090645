import { useState } from "react";
import styled from "styled-components";
import daniel from "../../assets/daniel.png";
import diane from "../../assets/diane.png";
import rich from "../../assets/rich.png";
import { colors } from "../../styles";
import { Button, Icon } from "../../ui";

const reviews = [
  {
    pic: rich,
    stars: 5,
    name: "Richard Ripplinger",
    text: "Red Peak installed solar panels at our home a couple years ago, and they have worked wonderfully just as promised by the sales representative.",
  },
  {
    pic: daniel,
    stars: 5,
    name: "Daniel Hale",
    text: "I'm glad I talked to Chris & Red Peak before making a decision. The solar plan the other company wanted to install would have *severely* underperformed. Instead of fast-talking me past the details, Chris explained things really well, answered all my questions and made it very easy to understand. He designed the system according to what we felt we needed, with lots of back & forth discussion and opportunities to assess what we were doing. We decided to upgrade to a higher-performance panel so that the space on our roof would get the best results. My past house's panels never worked well and we were always deeply disappointed (and hesitant to get solar again because of it), but these panels are working great! I've dealt with other solar panel companies and this is the first one I felt really good about after working with them.",
  },
  {
    pic: diane,
    stars: 5,
    name: "Diane Campbell",
    text: "Love my solar. Red Peak Solar, specifically Chris R. is honest, professional, and timely. I highly recommend him to anyone needing solar.",
  },
];

const ReviewContainer = styled.div`
  width: 320px;
`;

const NameRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StarsRow = styled.div`
  margin-top: 4px;
  display: flex;
  gap: 4px;
`;

const Name = styled.div`
  font-weight: 700;
`;

const Text = styled.div`
  margin-top: 8px;
`;

const shortLimit = 255;

const Review = ({ pic, stars, name, text }) => {
  const [expanded, setExpanded] = useState(false);

  const starIconNames = [
    ...Array(stars).fill("star"),
    ...Array(5 - stars).fill("star_outline"),
  ];
  const canExpand = text.length > shortLimit;
  const displayedText =
    expanded || !canExpand
      ? text
      : `${text.slice(0, text.slice(0, shortLimit).lastIndexOf(" "))} ...`;

  return (
    <ReviewContainer>
      <NameRow>
        <img src={pic} alt={name} width={36} height={36} />
        <Name>{name}</Name>
      </NameRow>
      <StarsRow>
        {starIconNames.map((iconName, index) => (
          <Icon
            key={index}
            name={iconName}
            color={iconName === "star" ? colors.yellow : colors.gray}
            fontSize="36px"
          />
        ))}
      </StarsRow>
      <Text expanded={expanded}>{displayedText}</Text>
      {canExpand && (
        <Button variant="link" onClick={() => setExpanded((old) => !old)}>
          {expanded ? "less" : "more"}
        </Button>
      )}
    </ReviewContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  max-width: 1080px;
  gap: 40px;
  justify-content: center;
`;

const Reviews = () => {
  return (
    <Container>
      {reviews.map((review) => (
        <Review key={review.name} {...review} />
      ))}
    </Container>
  );
};

export default Reviews;
