import styled from "styled-components";
import { palette } from "../../styles";
import { Button, Footer, Icon, Link, NavBar } from "../../ui";
import HeroWithSideText from "../HeroWithSideText";
import vehicleChargingImg from "../../assets/electric_vehicle_charging.jpg";
import energyAuditImg from "../../assets/energy_audit.jpg";
import wattsmartBatteryImg from "../../assets/wattsmart_battery.png";
import liftingPanelImg from "../../assets/lifting_solar_panel_onto_roof.jpg";
import rooferImg from "../../assets/roofer.jpg";
import radiantBarrierImg from "../../assets/Radiant-Barrier-Foil.jpg";
import atticFanImg from "../../assets/attic_fan.jpg";
import insulationImg from "../../assets/insulation.jpg";
import FeatureBox from "./FeatureBox";

const Container = styled.div``;

const Content = styled.div`
  background-color: ${palette.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;
`;

const FeatureGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 40px 0;
`;

const heroBlurb = (
  <>
    <p>
      {
        "Red Peak Solar offers a whole-home solution for your energy and roof needs."
      }
    </p>
    <p>
      {
        "Reducing the home's energy consumption, then adding solar, will get you to net zero and make your living space more comfortable."
      }
    </p>
  </>
);

const Services = () => {
  return (
    <Container>
      <NavBar />
      <HeroWithSideText title="Services" blurb={heroBlurb} />
      <Content>
        <FeatureGrid>
          <FeatureBox
            header="Solar"
            blurb="We offer solar installation at a fraction of the cost of other companies while still using the best equipment on the market."
            image={liftingPanelImg}
            cta={
              <Button>
                <Link inheritStyles to="/get-estimate">
                  Request a quote
                </Link>
              </Button>
            }
          />
          <FeatureBox
            header="Home energy audit"
            blurb="Reduce your energy consumption before you add solar."
            image={energyAuditImg}
            cta={
              <Button>
                <Link inheritStyles to="/get-estimate">
                  Request an energy audit
                </Link>
              </Button>
            }
          />
          <FeatureBox
            header="Wattsmart battery program"
            blurb="Get an upfront incentive of $600 per kW from Rocky Mountain Power for installing a whole-home solar battery."
            image={wattsmartBatteryImg}
            cta={
              <Button>
                <Link
                  isExternal
                  inheritStyles
                  target="_blank"
                  to="https://www.rockymountainpower.net/savings-energy-choices/wattsmart-battery-program.html"
                >
                  Learn more
                  <Icon name="open_in_new" fontSize="16px" margin="0 0 0 8px" />
                </Link>
              </Button>
            }
          />
          <FeatureBox
            header="Electric vehicle charging"
            blurb="Power your life. Power your home. Power your car."
            image={vehicleChargingImg}
            cta={
              <Button>
                <Link inheritStyles to="/get-estimate">
                  Request more information
                </Link>
              </Button>
            }
          />
          <FeatureBox
            header="Roofing"
            blurb="Roof not up to solar standards? No problem, Red Peak can reroof and make almost any home suitable for sustainability."
            image={rooferImg}
            cta={
              <Button>
                <Link inheritStyles to="/get-estimate">
                  Request a quote
                </Link>
              </Button>
            }
          />
          <FeatureBox
            header="Radiant barrier"
            blurb="Radiant barrier blocks 95% of the radiant heat entering attics. It reduces the load on the air conditioner and makes the upper floors of your home more comfortable in the summer."
            image={radiantBarrierImg}
            cta={
              <Button>
                <Link inheritStyles to="/get-estimate">
                  Request a quote
                </Link>
              </Button>
            }
          />
          <FeatureBox
            header="Solar attic fan"
            blurb="Solar attic fans use solar power to pull hot air from inside your attic allowing cooler outside air to take its place."
            image={atticFanImg}
            cta={
              <Button>
                <Link inheritStyles to="/get-estimate">
                  Request more information
                </Link>
              </Button>
            }
          />
          <FeatureBox
            header="Insulation"
            blurb="Adding insulation to your home will reduce heating and cooling costs and make your living space more comfortable."
            image={insulationImg}
            cta={
              <Button>
                <Link inheritStyles to="/get-estimate">
                  Request a quote
                </Link>
              </Button>
            }
          />
        </FeatureGrid>
      </Content>
      <Footer />
    </Container>
  );
};

export default Services;
