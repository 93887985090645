const colors = {
  red: "#ff0000",
  redDark: "#cc0000",
  redLight: "#ff9999",
  redBrand: "#b20211",
  redBrandDark: "#73000a",
  redBrandLight: "#b0797e",
  blue: "#10adfe",
  blueDark: "#0e587f",
  white: "#ffffff",
  black: "#000000",
  gray: "#888888",
  green: "#058011",
  greenDark: "#034f0b",
  greenLight: "#709e75",
  yellow: "#ffd600",
  yellowDark: "#cccc00",
};

const palette = {
  primary: colors.redBrand,
  primaryHighlight: colors.redBrandDark,
  primaryDisabled: colors.redBrandLight,
  primaryTextComplement: colors.white,
  secondary: colors.blueDark,
  secondaryTextComplement: colors.white,
  error: colors.red,
  errorHighlight: colors.redDark,
  errorDisabled: colors.redLight,
  errorTextComplement: colors.white,
  background: colors.white,
  backgroundTextComplement: colors.black,
  link: colors.blue,
  linkDisabled: colors.gray,
};

const fonts = {
  sans: "Open Sans",
  brand: "Agency FB",
};

export { colors, fonts, palette };
