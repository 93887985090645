import styled from "styled-components";
import { colors } from "../../styles";

const Container = styled.div`
  background-image: url(${(p) => p.image});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: ${colors.white};
  box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.5);
  height: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 26%;
  min-width: 400px;
  padding: 0 24px;
`;

const Header = styled.h2``;

const Blurb = styled.p`
  font-weight: 700;
`;

const VerticalFiller = styled.div`
  flex: 1 0 0;
`;

const CtaWrapper = styled.div`
  margin-bottom: 24px;
`;

const FeatureBox = ({ image, header, blurb, cta }) => {
  return (
    <Container image={image}>
      <Header>{header}</Header>
      <Blurb>{blurb}</Blurb>
      <VerticalFiller />
      <CtaWrapper>{cta}</CtaWrapper>
    </Container>
  );
};

export default FeatureBox;
