import emailjs from "@emailjs/browser";
import { Autocomplete } from "@react-google-maps/api";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import bountiful_power from "../../assets/bountiful_power.png";
import kaysville_power from "../../assets/kaysville_power.png";
import provo_power from "../../assets/provo_power.jpg";
import rocky_mountain_power from "../../assets/rocky_mountain_power.png";
import {
  emailjsPublicKey,
  emailjsServiceId,
  emailjsTemplateId,
} from "../../config";
import { colors, palette } from "../../styles";
import { Button, Footer, Link, NavBar, TextInput, Textarea } from "../../ui";
import Hero from "./Hero";

const Container = styled.div``;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 24px;
  margin-top: 40px;
`;

const Step = styled.div`
  display: ${(p) => (p.selected ? "block" : "none")};
  margin-top: 40px;
`;

const Instruction = styled.div`
  color: ${colors.white};
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin: 0 24px;
`;

const ImgSelection = styled.button`
  border: ${(p) =>
    p.selected ? `3px solid ${palette.primary}` : "3px solid transparent"};
  padding: 2px;
  display: flex;
`;

const Img = styled.img`
  border: 1px solid ${colors.gray};
  width: 128px;
  height: 128px;
  padding: 2px;
`;

const StepContent = styled.div`
  color: ${colors.white};
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const UtilityProviderGroup = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
`;

const ImgAlternative = styled.div`
  border: 1px solid ${colors.gray};
  background-color: ${colors.white};
  color: ${colors.black};
  width: 128px;
  height: 128px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const P = styled.div`
  color: ${colors.white};
  margin: 0 16px;
  text-align: center;
`;

const InstructionDetails = styled.div`
  color: ${colors.white};
  margin: 24px 16px 0 16px;
  text-align: center;
`;

const Footnote = styled.div`
  color: ${colors.white};
  margin: 40px 16px 0 16px;
  text-align: center;
  font-size: 14px;
`;

const inputWidth = "320px";

function GetEstimate() {
  const [step, setStep] = useState("utilityProvider");
  const [utilityProvider, setUtilityProvider] = useState("");
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [notes, setNotes] = useState("");

  // Scroll to top whenever the step changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const submit = () => {
    const powerBill = sessionStorage.getItem("powerBill");
    sessionStorage.removeItem("powerBill");
    setStep("complete");
    const templateParams = {
      powerBill,
      utilityProvider,
      address,
      firstName,
      lastName,
      email,
      phone,
      notes,
    };
    emailjs.send(
      emailjsServiceId,
      emailjsTemplateId,
      templateParams,
      emailjsPublicKey
    );
  };

  return (
    <Container>
      <NavBar />
      <Hero>
        <Content>
          <Step selected={step === "utilityProvider"}>
            <Instruction>Select your electricty provider.</Instruction>
            <StepContent>
              <UtilityProviderGroup>
                <ImgSelection
                  onClick={() => setUtilityProvider("Rocky Mountain Power")}
                  selected={utilityProvider === "Rocky Mountain Power"}
                >
                  <Img src={rocky_mountain_power} alt="Rocky Mountain Power" />
                </ImgSelection>
                <ImgSelection
                  onClick={() => setUtilityProvider("Bountiful City")}
                  selected={utilityProvider === "Bountiful City"}
                >
                  <Img src={bountiful_power} alt="Bountiful City" />
                </ImgSelection>
                <ImgSelection
                  onClick={() => setUtilityProvider("Kaysville City")}
                  selected={utilityProvider === "Kaysville City"}
                >
                  <Img src={kaysville_power} alt="Kaysville City" />
                </ImgSelection>
                <ImgSelection
                  onClick={() => setUtilityProvider("Provo City")}
                  selected={utilityProvider === "Provo City"}
                >
                  <Img src={provo_power} alt="Provo City" />
                </ImgSelection>
                <ImgSelection
                  onClick={() => setUtilityProvider("Other")}
                  selected={utilityProvider === "Other"}
                >
                  <ImgAlternative alt="Other">Other</ImgAlternative>
                </ImgSelection>
              </UtilityProviderGroup>
            </StepContent>
            <ButtonGroup>
              <Link to="/" inheritStyles>
                <Button variant="secondary">Back</Button>
              </Link>
              <Button
                onClick={() => setStep("address")}
                disabled={!utilityProvider}
              >
                Next
              </Button>
            </ButtonGroup>
          </Step>

          <Step selected={step === "address"}>
            <Instruction>What's your home address?</Instruction>
            <StepContent>
              <Autocomplete
                onLoad={setAutocomplete}
                onPlaceChanged={() =>
                  setAddress(autocomplete.getPlace().formatted_address)
                }
              >
                <TextInput
                  width={inputWidth}
                  value={address}
                  onChange={setAddress}
                />
              </Autocomplete>
            </StepContent>
            <ButtonGroup>
              <Button
                variant="secondary"
                onClick={() => setStep("utilityProvider")}
              >
                Back
              </Button>
              <Button onClick={() => setStep("name")} disabled={!address}>
                Next
              </Button>
            </ButtonGroup>
          </Step>

          <Step selected={step === "name"}>
            <Instruction>What's your first and last name?</Instruction>
            <StepContent>
              <TextInput
                width={inputWidth}
                placeholder="First name..."
                value={firstName}
                onChange={setFirstName}
              />
              <TextInput
                width={inputWidth}
                placeholder="Last name..."
                value={lastName}
                onChange={setLastName}
              />
            </StepContent>
            <ButtonGroup>
              <Button variant="secondary" onClick={() => setStep("address")}>
                Back
              </Button>
              <Button
                onClick={() => setStep("email")}
                disabled={!firstName || !lastName}
              >
                Next
              </Button>
            </ButtonGroup>
          </Step>

          <Step selected={step === "email"}>
            <Instruction>What's your email address?</Instruction>
            <StepContent>
              <TextInput
                width={inputWidth}
                placeholder="Email address..."
                value={email}
                onChange={setEmail}
              />
            </StepContent>
            <ButtonGroup>
              <Button variant="secondary" onClick={() => setStep("name")}>
                Back
              </Button>
              <Button onClick={() => setStep("phone")}>
                {email ? "Next" : "Skip"}
              </Button>
            </ButtonGroup>
            <Footnote>
              We will never share your personal information with other
              individuals or organizations.
            </Footnote>
          </Step>

          <Step selected={step === "phone"}>
            <Instruction>What's your phone number?</Instruction>
            <StepContent>
              <TextInput
                width={inputWidth}
                placeholder="Phone number..."
                value={phone}
                onChange={setPhone}
              />
            </StepContent>
            <ButtonGroup>
              <Button variant="secondary" onClick={() => setStep("email")}>
                Back
              </Button>
              <Button onClick={() => setStep("notes")}>Next</Button>
            </ButtonGroup>
            <Footnote>
              We will never share your personal information with other
              individuals or organizations.
            </Footnote>
          </Step>

          <Step selected={step === "notes"}>
            <Instruction>
              Almost done! Please share any other information you think would be
              helpful.
            </Instruction>
            <InstructionDetails>
              For example, you can let us know if you are interested in an
              energy audit, a radiant barrier installation, or any of our other
              services.
            </InstructionDetails>
            <StepContent>
              <Textarea
                width={inputWidth}
                placeholder="Additional info..."
                value={notes}
                onChange={setNotes}
              />
            </StepContent>
            <ButtonGroup>
              <Button variant="secondary" onClick={() => setStep("phone")}>
                Back
              </Button>
              <Button onClick={submit}>Get estimate</Button>
            </ButtonGroup>
          </Step>

          <Step selected={step === "complete"}>
            <Instruction>Estimate request submitted!</Instruction>
            <StepContent>
              <P>
                You will receive a phone call soon to go over your solar
                estimate.
              </P>
            </StepContent>
            <ButtonGroup>
              <Link to="/" inheritStyles>
                <Button>Back to homepage</Button>
              </Link>
            </ButtonGroup>
          </Step>
        </Content>
      </Hero>
      <Footer />
    </Container>
  );
}

export default GetEstimate;
