import styled from "styled-components";
import { palette } from "../styles";
import { Footer, NavBar } from "../ui";

const Container = styled.div``;

const Content = styled.div`
  background-color: ${palette.background};
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Products = () => {
  return (
    <Container>
      <NavBar />
      <Content>More information will be available on this page soon!</Content>
      <Footer />
    </Container>
  );
};

export default Products;
