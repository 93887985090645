import styled from "styled-components";
import { palette } from "../../styles";
import { Footer, NavBar } from "../../ui";
import Hero from "./Hero";
import Reviews from "./Reviews";

const Container = styled.div`
  position: relative;
`;

const Content = styled.div`
  background-color: ${palette.background};
  padding-top: 80px;
`;

function Home() {
  return (
    <Container>
      <NavBar />
      <Hero />
      <Content>
        <Reviews />
        <Footer />
      </Content>
    </Container>
  );
}

export default Home;
