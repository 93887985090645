import styled from "styled-components";
import { palette } from "../../styles";
import { Button, Footer, Link, NavBar } from "../../ui";

const Container = styled.div``;

const Content = styled.div`
  background-color: ${palette.background};
  margin: 0 16px;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  margin: 16px;
  font-size: 32px;
  font-weight: 700;
  color: ${palette.secondary};

  @media (min-width: 960px) {
    margin-top: 80px;
    font-size: 56px;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  margin-bottom: 40px;
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${palette.secondary};
`;

const SectionList = styled.div`
  max-width: 960px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Incentives = () => {
  return (
    <Container>
      <NavBar />
      <Top>
        <Title>Federal and state solar incentives</Title>
        <Button>
          <Link inheritStyles to="/get-estimate">
            Request an estimate
          </Link>
        </Button>
      </Top>
      <Content>
        <SectionList>
          <div>
            <SectionTitle>Federal tax credit</SectionTitle>
            <p>
              {
                "Any solar system installed between 2022-2032 will be eligible for a 30% federal tax credit. There is no cap on the amount that can be claimed. That is a non-refundable tax credit so some people will not be eligible. For example, if the only source of income is social security, that person would likely not receive any credit. For those that don't capture the whole credit in the first year, it carries over for up to five years."
              }
            </p>
            <p>
              <span>
                {
                  "Red Peak Solar does not give tax advice. Consult your CPA for specifics regarding tax credits. Read more about the credit from "
                }
              </span>
              <Link
                target="_blank"
                to="https://www.energy.gov/eere/solar/homeowners-guide-federal-tax-credit-solar-photovoltaics"
                isExternal
              >
                energy.gov
              </Link>
              <span>{"."}</span>
            </p>
          </div>

          <div>
            <SectionTitle>State of Utah solar credit</SectionTitle>
            <p>
              <span>
                {
                  "For systems installed in 2022, the maximum credit is $800. Systems installed in 2023 will have a maximum tax credit of $400. The credit expires at the beginning of 2024. Learn more about Utah tax credits "
                }
              </span>
              <Link
                target="_blank"
                to="https://energy.utah.gov/tax-credits/renewable-energy-systems-tax-credit/"
                isExternal
              >
                here
              </Link>
              <span>{"."}</span>
            </p>
          </div>

          <div>
            <SectionTitle>Wattsmart battery program</SectionTitle>
            <p>
              <span>
                {
                  "A Rocky Mountain Power incentive program based upon the size of the unit. Homeowners that have a qualifying battery can receive $600 per kW upon enrollment and $15 per kW per year for participation. Visit this link from "
                }
              </span>
              <Link
                target="_blank"
                to="https://www.rockymountainpower.net/content/dam/pcorp/documents/en/rockymountainpower/savings-energy-choices/wattsmart-batteries/RMP_BatteryStorageProgram_customer_FINAL.pdf"
                isExternal
              >
                Rocky Mountain Power
              </Link>
              <span>{" for more information."}</span>
            </p>
          </div>

          <div>
            <SectionTitle>Net metering</SectionTitle>
            <p>
              {
                "Solar customers will receive a net meter from their utility. The only difference with this new meter is it can spin backwards and forwards. In the middle of the day you will likely be overproducing power and forcing your meter to spin backwards. Then at nightime when you're back using grid power instead of solar, your meter spins forward again using the credits you gained during the day."
              }
            </p>
            <p>
              {
                "If you have Rocky Mountain Power, the net metering credit is not one-to-one, it's closer to 0.5-to-one. For that reason, if you want to get to net zero, we usually design your system to produce 130-145% of your current usage."
              }
            </p>
          </div>
        </SectionList>
      </Content>
      <Footer />
    </Container>
  );
};

export default Incentives;
