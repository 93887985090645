const emailjsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const emailjsUserId = process.env.REACT_APP_EMAILJS_USER_ID;
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export {
  emailjsPublicKey,
  emailjsServiceId,
  emailjsTemplateId,
  emailjsUserId,
  googleMapsApiKey,
};
