import styled from "styled-components";
import { colors, palette } from "../styles";

const thumbWidth = 64;

const BubbleWrap = styled.div`
  width: ${(p) => p.width + 32}px;
  height: 48px;
  border-radius: 24px;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: ${(p) => p.width}px;
  height: 32px;
  background: transparent; /* Otherwise white in Chrome */
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 32px;
    width: ${thumbWidth}px;
    border-radius: 16px;
    background: transparent;
    cursor: pointer;
    margin-top: 0;
  }

  &::-webkit-slider-runnable-track {
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
    background: transparent;
  }

  &::-moz-range-thumb {
    border: none;
    height: 32px;
    width: ${thumbWidth}px;
    border-radius: 16px;
    background: transparent;
    cursor: pointer;
    margin-top: 0;
  }

  &::-moz-range-track {
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
    background: transparent;
  }

  &::-ms-thumb {
    border: none;
    height: 32px;
    width: ${thumbWidth}px;
    border-radius: 16px;
    background: transparent;
    cursor: pointer;
    margin-top: 0;
  }

  &::-ms-track {
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
    background: transparent;
  }

  &:focus {
    outline: none;
  }
`;

const Container = styled.div`
  width: ${(p) => p.width}px;
  position: relative;
  height: 32px;
`;

const Label = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${thumbWidth}px;
  font-weight: 700;
  position: absolute;
  left: ${(p) => p.left};
  top: 0;
  height: 32px;
  user-select: none;
  border-radius: 16px;
  background-color: ${palette.primary};
  color: ${palette.primaryTextComplement};
`;

const Track = styled.div`
  position: absolute;
  top: 12px;
  left: 32px;
  border-radius: 4px;
  background-color: ${colors.gray};
  height: 8px;
  width: calc(100% - ${thumbWidth}px);
`;

const MinLabel = styled.span`
  user-select: none;
  color: ${palette.primary};
  font-size: 12px;
  font-weight: 700;
  right: calc(100% - 28px);
  top: 7px;
  position: absolute;
`;

const MaxLabel = styled.span`
  user-select: none;
  color: ${palette.primary};
  font-size: 12px;
  font-weight: 700;
  left: calc(100% - 28px);
  top: 7px;
  position: absolute;
`;

const Slider = ({
  value,
  label,
  width,
  onChange,
  min = 0,
  max = 10,
  step = 1,
  minLabel,
  maxLabel,
}) => {
  const rangeOfLeftInPercent = (100 * (width - thumbWidth)) / width;
  const left = `${(rangeOfLeftInPercent * (value - min)) / (max - min)}%`;

  return (
    <BubbleWrap width={width}>
      <Container width={width}>
        {minLabel && <MinLabel>{minLabel}</MinLabel>}
        <Track />
        {maxLabel && <MaxLabel>{maxLabel}</MaxLabel>}
        <Label left={left}>{label ?? value}</Label>
        <Input
          type="range"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          min={min}
          max={max}
          step={step}
          width={width}
        />
      </Container>
    </BubbleWrap>
  );
};

export default Slider;
