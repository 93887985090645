import styled from "styled-components";
import { palette } from "../styles";
import Link from "./Link";

const Container = styled.div`
  margin-top: 80px;
  min-height: 200px;
  background-color: ${palette.secondary};
  color: ${palette.secondaryTextComplement};
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  gap: 40px;
`;

const NavItemContainer = styled.div``;

const NavItemsBlock = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 752px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  background-color: ${palette.secondaryTextComplement};
  height: 1px;
  border-radius: 0.5px;
  flex: 1 0 auto;
`;

const Copyright = styled.div`
  font-size: 14px;
`;

const navItems = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Services",
    path: "/services",
  },
  {
    label: "Incentives",
    path: "/incentives",
  },
  {
    label: "Contact",
    path: "/contact",
  },
  {
    label: "Get started",
    path: "/get-estimate",
  },
  {
    label: "Privacy policy",
    path: "/privacy",
  },
];

const copyrightText = `© ${new Date().getFullYear()} Red Peak Solar, LLC. All rights reserved.`;

const NavItem = ({ label, path, isExternal }) => {
  if (path) {
    return (
      <Link to={path} isExternal={isExternal} inheritStyles>
        <NavItemContainer>{label}</NavItemContainer>
      </Link>
    );
  }

  return <NavItemContainer>{label}</NavItemContainer>;
};

const Footer = () => {
  return (
    <Container>
      <NavItemsBlock>
        {navItems.map((item) => (
          <NavItem key={item.path || item.label} {...item} />
        ))}
      </NavItemsBlock>
      <Divider />
      <Copyright>{copyrightText}</Copyright>
    </Container>
  );
};

export default Footer;
