import { LoadScript } from "@react-google-maps/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { googleMapsApiKey } from "./config";
import Commercial from "./pages/Commercial";
import Contact from "./pages/Contact";
import GetEstimate from "./pages/GetEstimate";
import Home from "./pages/Home";
import Incentives from "./pages/Incentives";
import Privacy from "./pages/Privacy";
import Products from "./pages/Products";
import Residential from "./pages/Residential";
import Services from "./pages/Services";

const libraries = ["places"];

function App() {
  return (
    <LoadScript
      libraries={libraries}
      googleMapsApiKey={googleMapsApiKey}
      loadingElement={<div />}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/get-estimate" element={<GetEstimate />} />
          <Route path="/products" element={<Products />} />
          <Route path="/residential" element={<Residential />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/incentives" element={<Incentives />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </LoadScript>
  );
}

export default App;
