import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import heroBackground from "../../assets/hero_background.jpg";
import { colors } from "../../styles";
import { Button, Icon, Slider } from "../../ui";

const Background = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  z-index: -1;
  height: calc(100vh - 64px);
  background-image: url(${heroBackground});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
`;

const Container = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
`;

const ScrollDownDiv = styled.div`
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: ${colors.white};
`;

const ScrollDownText = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const Text = styled.div`
  color: ${colors.white};
  font-weight: 700;
  font-size: 28px;
  margin: 80px 40px 0 40px;
  text-align: center;
`;

const Div600h = styled.div`
  height: 600px;
`;

const Form = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

const SmallText = styled.div`
  color: ${colors.white};
  font-weight: 700;
  margin: 80px 40px 0 40px;
  text-align: center;
`;

const Hero = () => {
  const [powerBill, setPowerBill] = useState(150);
  const navigate = useNavigate();

  const onClick = () => {
    sessionStorage.setItem("powerBill", powerBill);
    navigate("/get-estimate");
  };

  return (
    <>
      <Background />
      <Container>
        <Text>See if solar is right for you.</Text>
        <SmallText>Enter your monthly power bill.</SmallText>
        <Form>
          <Slider
            value={powerBill}
            min={50}
            minLabel="$50"
            max={450}
            maxLabel="$450"
            onChange={setPowerBill}
            width={320}
            step={10}
            label={`$${powerBill}`}
          />
          <Button onClick={onClick}>Start</Button>
        </Form>
        <Div600h />
        <ScrollDownDiv>
          <Icon name="mouse" fontSize="36px" />
          <ScrollDownText>SCROLL DOWN</ScrollDownText>
        </ScrollDownDiv>
      </Container>
    </>
  );
};

export default Hero;
