import styled from "styled-components";
import { palette } from "../../styles";
import { Footer, Link, NavBar } from "../../ui";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Content = styled.div`
  background-color: ${palette.background};
  margin: 0 16px;
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
`;

const Title = styled.div`
  margin: 16px;
  font-size: 32px;
  font-weight: 700;
  color: ${palette.secondary};

  @media (min-width: 960px) {
    margin-top: 80px;
    font-size: 56px;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  margin-bottom: 40px;
`;

const SectionList = styled.div`
  max-width: 960px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Contact = () => {
  return (
    <Container>
      <NavBar />
      <Top>
        <Title>Contact</Title>
      </Top>
      <Content>
        <SectionList>
          <div>
            <div>
              <b>Email</b>
            </div>
            <div>
              <Link isExternal to="mailto:info@redpeaksolar.com">
                info@redpeaksolar.com
              </Link>
            </div>
          </div>
          <div>
            <div>
              <b>Phone</b>
            </div>
            <div>
              <Link isExternal to="tel:+18017190071">
                801-719-0071
              </Link>
              <span>{" call or text"}</span>
            </div>
          </div>
          <div>
            <p>
              <span>
                {
                  "Red Peak Solar believes quality solar should be accessible at a fraction of the cost of other companies. Call, text, or email us for a free estimate. "
                }
              </span>
              <Link to="/privacy">Privacy policy</Link>
            </p>
          </div>
        </SectionList>
      </Content>
      <Footer />
    </Container>
  );
};

export default Contact;
